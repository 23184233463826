import React from "react"
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from "../components/layout"
import FormItem from "../components/screens/Portfolio/FormItem"
import { Helmet } from "react-helmet"
import { OutboundLink } from 'gatsby-plugin-gtag'

const PortfolioPage = ({ data }) => (
  <Layout pageInfo={{ pageName: "Portfolio" }} sitePage="site-page">
    <Helmet defer={false}>
        <title>Portfolio</title>
        <meta name="description" content="Building great software products for global companies" />
    </Helmet>

    <div className="wrapper-portfolio">
      <h1 className="title-blog">
        Checkout some our recent case studies
      </h1>
      <FormItem>
        {data.allStrapiPortfolio.edges.map(document => (
          <li key={document.node.id} className="image-element-class col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <OutboundLink href={`/${document.node.url}`}>
              <Img fixed={document.node.image_header.childImageSharp.fixed} alt="900x500" className="image-item-blog lazyload"/>
            </OutboundLink>
            <OutboundLink href={`/${document.node.url}`} className="btn-portfolio">
              <p className="title-portfolio">{document.node.Title}</p>
            </OutboundLink>
          </li>
        ))}
      </FormItem>
    </div>
  </Layout>
)

export default PortfolioPage

export const pageQuery = graphql`
  query PortfolioQuery {
    allStrapiPortfolio {
      edges {
        node {
          id
          Title
          image_header {
            childImageSharp {
              fixed (width: 2000) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          url
        }
      }
    }
  }
`
